<template>
  <div class="admin-container" v-if="data">
    <h1 class="col-secondary-1">Kontakty</h1>
    <p class="mt-4 col-secondary-2">
      V případě dotazů či připomínek se na nás neváhejte obrátit.
    </p>
    <v-btn small color="primary" tile @click="toMessages">
      <div class="d-flex flex-align-center">
        <img
          :src="require('@/assets/poslat_zpravu.svg')"
          class="icon-wrapp mr-2"
          style="width: 15px; height: 15px"
        />
        Poslat zprávu
      </div>
    </v-btn>
    <v-row style="margin-top: 80px">
      <v-col cols="12" md="3" sm="6">
        <h4 class="col-secondary-1">Váš projektový manager</h4>
        <v-card class="elevation-1 pa-4 mt-6" tile min-height="180">
          <v-card-subtitle>
            <div class="d-block col-secondary-2">{{ data.full_name }}</div>
          </v-card-subtitle>
          <v-card-text>
            <a
              :href="`tel:${data.phone_number}`"
              class="d-flex flex-align-center col-secondary-2 text-no-underline"
            >
              <img
                class="icon-wrapp mr-4"
                :src="require('@/assets/phone.svg')"
                alt=""
              />
              {{ data.phone_number ? data.phone_number.replace(/(\+\d{3})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4') : "" }}
            </a>
            <a
              :href="`mailto:${data.email}`"
              class="d-flex flex-align-center mt-5 col-secondary-2 text-no-underline"
            >
              <img
                class="icon-wrapp mr-4"
                :src="require('@/assets/email.svg')"
                alt=""
              />
              {{ data.email }}
            </a>
          </v-card-text>
          <v-card-actions style="margin-top: -26px"> </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <h4 class="col-secondary-1">MyMD21</h4>
        <v-card class="elevation-1 pa-4 mt-6" tile min-height="180">
          <v-card-text>
            <a
              :href="`tel:+420608333321`"
              class="d-flex flex-align-center col-secondary-2 text-no-underline"
            >
              <img
                class="icon-wrapp mr-4"
                :src="require('@/assets/phone.svg')"
                alt=""
              />
              +420 608 333 321
            </a>
            <a
              :href="`mailto:info@mymd21.cz`"
              class="d-flex flex-align-center mt-5 col-secondary-2 text-no-underline"
            >
              <img
                class="icon-wrapp mr-4"
                :src="require('@/assets/email.svg')"
                alt=""
              />
              info@mymd21.cz
            </a>
            <a
              href="www.mymd21.cz"
              target="_blank"
              class="d-flex flex-align-center mt-5 col-secondary-2 text-no-underline"
            >
              <img
                class="icon-wrapp mr-4"
                :src="require('@/assets/www.svg')"
                alt=""
              />
              www.mymd21.cz
            </a>
          </v-card-text>
          <v-card-actions style="margin-top: -26px"> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <div class="mt-10 d-flex">
      <a
        href="https://www.facebook.com/mymd21/"
        class="mr-10 d-flex flex-column flex-justify-center align-center col-secondary-2 text-no-underline"
        target="_blank"
      >
        <img
          :src="require('@/assets/facebook.svg')"
          alt=""
          class="social-sites-icon"
        />
        <div class="mt-4">Facebook</div>
      </a>
      <a
        href="https://www.instagram.com/my_md21/"
        class="d-flex flex-column align-center col-secondary-2 text-no-underline"
        target="_blank"
      >
        <img
          :src="require('@/assets/instagram.svg')"
          alt=""
          class="social-sites-icon"
        />
        <div class="mt-4">Instagram</div>
      </a>
    </div>

    <!-- <div class="map">
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=znojmo&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import authHandler from "../api/authHandler";
import bus from "../plugins/bus";
import buildingService from "../api/buildingService";

export default {
  data() {
    return {
      data: null,
    };
  },
  async created() {
    try {
      //   await this.$store.dispatch("getCurrentUser");
      if (this.getSelectedBuilding() != null) {
        await this.contacts();
      } else {
        this.$router.push({ name: "Dashboard" });
        bus.$emit("snackbar", {
          text: "Není vybrána žádná stavba.",
          color: "error",
        });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: "Login" });
    }
  },
  methods: {
    toMessages() {
      if (["END_USER", "COMPANY_CUSTOMER"].includes(this.user.role)) {
        this.$router.push({
          name: "MessagesDetail",
          params: { id: this.data.id },
          query: { section: "contact" },
        });
      }
      if (["PROJECT_MANAGER", "SUPERUSER"].includes(this.user.role)) {
        this.$router.push({
          name: "MessagesDetail",
          params: { id: this.getSelectedBuilding().investor.id },
          query: { section: "contact" },
        });
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return building;
    },
    async contacts() {
      try {
        bus.$emit("processing");
        const data = await buildingService.contacts();
        console.debug("data-contacts", data);
        this.data = data;
        bus.$emit("processing", false);
      } catch (error) {
        console.debug(error);
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
    // async getBuildings() {
    //   try {
    //     bus.$emit("processing");
    //     const data = await buildingService.index();
    //     console.log(data);
    //     this.data = data;
    //     bus.$emit("processing", false);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    phone() {
      return {
        backgroundImage: `url(${require("@/assets/phone.svg")} !important`,
      };
    },
    email() {
      return {
        backgroundImage: `url(${require("@/assets/email.svg")} !important`,
      };
    },
    www() {
      return {
        backgroundImage: `url(${require("@/assets/www.svg")} !important`,
      };
    },
  },
};
</script>

<style></style>
